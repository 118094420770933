import React, { useState, useEffect } from "react";
import {
  isLoggedIn,
  getUserDetails,
  gotoSurveyMonkey,
} from "../utils/services/methods";
import { _post } from "../utils/services/api.service";
import EnrollmentNotification from "../components/CourseDetail/child-components/EnrollmentNotification";
import Layout from "../components/Layout/Layout";
import useApplicationNotificationData from "../hooks/application-notification-data";
import queryString from "query-string";

const FellowshipApply = props => {
  const queryParams = queryString.parse(props.location.search);
  const [failure, setFailure] = useState(false);
  const [notification, setNotification] = useState(null);
  const applicationNotificationData = useApplicationNotificationData();
  const [enrollmentNotification, setEnrollmentNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const createApplication = async () => {
    let pendingProductId, pendingProgramId, preferredLanguage;
    if (typeof window === "object") {
      const pendingApplication = JSON.parse(
        localStorage.getItem("pendingApplicationDetails")
      );
      preferredLanguage = navigator.language || navigator.userLanguage;
      pendingProductId = pendingApplication?.productid;
      pendingProgramId = pendingApplication?.program_id;
    }
    const userDetails = getUserDetails();
    const response = await _post({
      endpoint: "applications",
      args: {
        email: userDetails.email,
        product_id: queryParams.productid
          ? queryParams.productid
          : pendingProductId,
        program_id: queryParams.program_id
          ? queryParams.program_id
          : pendingProgramId,
        language_preference: preferredLanguage?.includes("es") ? "es" : "en",
      },
    });

    if (response.success) {
      if (typeof window === "object") {
        localStorage.removeItem("pendingApplicationDetails");
      }
      gotoSurveyMonkey();
    } else {
      setNotification({
        status: "fail",
        type: "application",
      });
      setLoading(false);
      setFailure(true);
    }
  };
  useEffect(() => {
    if (isLoggedIn()) {
      createApplication();
    } else {
      if (typeof window === "object") {
        localStorage.setItem(
          "pendingApplicationDetails",
          JSON.stringify({
            productid: queryParams.productid,
            program_id: queryParams.program_id,
          })
        );
        window.location.replace(
          `${process.env.GATSBY_login_url}sso?redirect=${window.location.origin}/fellowship/apply?program_id=${queryParams.program_id}&productid=${queryParams.productid}`
        );
      }
    }
  }, []);

  return (
    <div>
      {failure ? (
        <Layout>
          <EnrollmentNotification
            setEnrollmentNotification={setEnrollmentNotification}
            notification={notification}
            notificationData={applicationNotificationData}
          />
        </Layout>
      ) : <Layout loading={loading}></Layout>}
    </div>
  );
};

export default FellowshipApply;
